import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import DirectDeposit from '../views/DirectDeposit.vue'
import Resources from '../views/Resources.vue'
import Network from '../views/Network.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'About',
		component: Home
	},
	{
		path: '/ach',
		name: 'Direct Deposit',
		component: DirectDeposit
	},
	{
		path: '/resources',
		name: 'Resources',
		component: Resources
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router