export default {
	data() {
		return {
			offset: 0,
			window: {
				width: 0,
				height: 0,
			},
		}
	},
	computed: {
		isDesktop() {
			return this.window.width >= 1080;
		},
		isTablet() {
			return this.window.width < 1080 && this.window.width > 540;
		},
		isMobile() {
			return this.window.width <= 540;
		},
	},
	methods: {
		getOffset() {
			this.offset = window.pageYOffset / 3;
		},
		handleResize() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
		},
		getImage(path) {
			var newPath = path;
			if (this.isMobile) {

				newPath = newPath.replace(".", "-m.");
			} else {

			}
			return newPath;
		},

	},
	created() {
		window.addEventListener("resize", this.handleResize);
		window.addEventListener("scroll", this.getOffset);
		this.handleResize();
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.handleResize);
		window.removeEventListener("scroll", this.getOffset);
	},
};