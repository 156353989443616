<template>
	<div class="toggle">
		<div class="heading" @click="open = !open">
			<inline-svg
				src="/images/black-arrow.svg"
				:class="{ rotate: open }"
			></inline-svg>
			<h3><slot name="heading">Heading</slot></h3>
		</div>
		<div v-show-slide="open" class="body">
			<div class="inner"><slot name="body">Body</slot></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Toggle",
		components: {},
		data() {
			return {
				open: false,
			};
		},
	};
</script>

<style lang="scss">
	.toggle {
		display: flex;
		flex-direction: column;
		padding-top: 22px;
		padding-bottom: 22px;
		border-bottom: 1px solid #e6e6e6;
		line-height: 1.2em;
		color: #333;
		width: 1128px;
		.heading {
			position: relative;
			svg {
				min-width: 20px;
				max-width: 20px;
				position: relative;
				left: 15px;
				transition: transform 0.3s ease;
				&.rotate {
					transform: rotate(90deg);
				}
			}
			h3 {
				font-size: 20px;
				font-weight: 500;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				left: 50px;
			}
			cursor: pointer;
		}
		.body {
			.inner {
				font-size: 16px;
				line-height: 1.75;
				padding-left: 85px;
				margin-top: 8px;
				display: inline;
				strong {
					display: inline;
					font-weight: 600;
				}
				div {
					display: inline-block;
					margin-bottom: 17px;
					&.indented {
						padding-left: 20px;
					}
				}
				a {
					color: #333;
					display: inline;
					font-weight: 600;
				}
				ul {
					flex-direction: column;
					padding-left: 42px;
					position: relative;
					font-size: 16px;
					line-height: 1.75em;
					margin-bottom: 20px;
					li {
						display: list-item;
					}
					&.arrow {
						list-style: none;
						li:before {
							content: ">";
							position: absolute;
							left: 15px;
							font-weight: 600;
						}
					}
				}
				ol {
					flex-direction: column;
					padding-left: 25px;
					position: relative;
					font-size: 16px;
					line-height: 1.75;
					margin-bottom: 20px;
				}
				h3 {
					font-size: 16px;
				}
				h4 {
					margin-bottom: 8px;
					display: inline-block;
				}
				> :last-child,
				.no-margin {
					margin-bottom: 0px;
				}
			}
		}
		&:last-child {
			border-bottom: 0;
		}
		.small-text,
		.small-text * {
			font-size: 13px;
			line-height: 2.15em;
		}
	}

	@media (max-width: 1079px) {
		.toggle {
			.heading {
				h3 {
					width: calc(100% - 100px);
				}
			}
		}
	}

	@media (max-width: 540px) {
		.toggle {
			.heading {
				svg {
					min-width: 12px;
					max-width: 12px;
					left: 18px;
				}
				h3 {
					font-size: 13px;
					justify-content: center;
					text-align: center;
					width: calc(100% - 90px);
					line-height: 1.6em;
				}
			}
			.body {
				.inner {
					font-size: 12px;
					line-height: 1.5;
					padding-left: 35px;
					margin-top: 15px;
					div {
						margin-bottom: 17px;
						width: calc(100% - 30px);
						&.indented {
							padding-left: 0px;
						}
					}
					ul {
						padding-left: 25px;
						font-size: 12px;
						line-height: 1.5em;
						margin-bottom: 20px;
						width: 90%;
						li {
							margin-bottom: 5px;
						}
						&.arrow {
							li:not(:last-child) {
								margin-bottom: 20px;
							}
							li:before {
								left: 5px;
							}
						}
					}
					ol {
						padding-left: 0px;
						font-size: 12px;
						line-height: 1.5;
						margin-bottom: 20px;
						width: 90%;
					}
					h3 {
						font-size: 13px;
						width: 90%;
						display: block;
						margin-bottom: 10px;
					}
					h4 {
						margin-bottom: 20px;
					}
				}
			}
			.small-text,
			.small-text * {
				font-size: 10px;
				line-height: 1.8em;
			}
		}
	}
</style>
