<template>
	<div id="carousel">
		<div
			id="slides"
			@mouseenter="paused = true"
			@mouseleave="
				paused = false;
				selectedUser = '';
			"
		>
			<transition name="fade">
				<div key="0" v-if="activeIndex == 0" class="slide first">
					<div
						class="slide-background"
						:style="{
							'background-image':
								'url(' +
								getImage('/images/bg/bg-stairs.jpg') +
								')',
							'background-position': 'left center',
						}"
					></div>
					<div class="content site-padding">
						<h1>Move your back office forward</h1>
					</div>
				</div>
				<div
					id="operator-slide"
					key="1"
					v-else-if="activeIndex == 1"
					class="slide bg-purple"
				>
					<div
						class="slide-background"
						:style="{
							'background-image':
								'url(' +
								getImage('/images/bg/bg-operator.jpg') +
								')',
							'background-position': 'right center',
						}"
					></div>
					<div class="content site-padding">
						<h1>
							<span>Dominate</span><span> the field </span>
							<span> of pay.</span>
						</h1>
						<p>
							With Iberia’s back office at your six, it’s never
							been easier to finish first. Our ultra reliable
							automatic reconciliation system chews through big
							data like the well-oiled machines you rely on, day
							in and day out. And our conflict proven team of
							diagnostic professionals are always on watch, so you
							can bet the only thing standing between you and your
							next check, is a login.
							<a
								href="/register.aspx"
								class="join-text text-purple"
							>
								JOIN NOW >
							</a>
						</p>
					</div>
				</div>
				<div
					id="purchaser-slide"
					key="2"
					v-else-if="activeIndex == 2"
					class="slide bg-pink"
				>
					<div
						class="slide-background"
						:style="{
							'background-image':
								'url(' +
								getImage('/images/bg/bg-purchaser.jpg') +
								')',
							'background-position': 'center center',
						}"
					></div>
					<div class="content site-padding">
						<h1><span>Trade up.</span><span> Deal down.</span></h1>
						<p>
							Protect the integrity of your hard won first
							purchaser legacy with Iberia's rock solid
							reconciliation system. Led by a dream-team staff of
							seasoned industry vets with decades of experience,
							the Iberia protocol ensures your check runs sprint
							flawlessly from book to bank in record time. Every
							time.
							<a
								href="/register.aspx"
								class="join-text text-pink"
							>
								JOIN NOW >
							</a>
						</p>
					</div>
				</div>
				<div
					id="transporter-slide"
					key="3"
					v-else-if="activeIndex == 3"
					class="slide bg-orange"
				>
					<div
						class="slide-background"
						:style="{
							'background-image':
								'url(' +
								getImage('/images/bg/bg-transporter.jpg') +
								')',
							'background-position': 'center center',
						}"
					></div>
					<div class="content site-padding">
						<h1>
							<span>Welcome</span><span> to easy </span
							><span>street.</span>
						</h1>
						<p>
							Stay ahead of the revenue curve with turn-key tech
							that turns on a dime. Iberia's proven automatic
							reconciliation system navigates unpredictable
							stakeholder expectations on the fly so you can stop
							pumping the brakes on profit and start punching
							tickets to payday in the fast lane. And our equally
							nimble support staff are available 24/7, so you’re
							never left rolling solo.
							<a
								href="/register.aspx"
								class="join-text text-orange"
							>
								JOIN NOW >
							</a>
						</p>
					</div>
				</div>
				<div
					id="owner-slide"
					key="4"
					v-else-if="activeIndex == 4"
					class="slide bg-black"
				>
					<div
						class="slide-background"
						:style="{
							'background-image':
								'url(' +
								getImage('/images/bg/bg-owner.jpg') +
								')',
							'background-position': 'left center',
						}"
					></div>
					<div class="content site-padding">
						<h1>
							<span>Get the</span><span> royalty </span
							><span>treatment.</span>
						</h1>
						<p>
							Move to the front of the line with a tech platform
							that serves up checks on a silver platter. Iberia's
							instant reconciliation system is your automated
							ace-in-the-hole that guarantees a winner's seat at
							the payday guessing game, month after month. And
							with black-tie support available 24/7, it's never
							been easier to cash in on cashing out what's yours.
							<a href="/register.aspx" class="join-text">
								JOIN NOW >
							</a>
						</p>
					</div>
				</div>
			</transition>

			<a
				id="mobile-join-text"
				v-show="activeIndex != 0"
				href="/register.aspx"
				class="join-text"
				:class="{
					'text-purple': activeIndex == 1,
					'text-pink': activeIndex == 2,
					'text-orange': activeIndex == 3,
					'text-white': activeIndex == 4,
				}"
			>
				JOIN NOW >
			</a>

			<div id="mobile-carousel-nav" v-show="!isDesktop">
				<inline-svg
					class="arrow-left arrow-fade"
					src="/images/carousel-arrow.svg"
					fill="#ffffff"
					@click.native="prev($event, true)"
				></inline-svg>

				<div id="dots">
					<inline-svg
						:class="{ 'selected-dot': activeIndex == 0 }"
						src="/images/carousel-dot.svg"
						:fill="carouselNavFill"
					></inline-svg>
					<inline-svg
						:class="{ 'selected-dot': activeIndex == 1 }"
						src="/images/carousel-dot.svg"
						:fill="carouselNavFill"
					></inline-svg>
					<inline-svg
						:class="{ 'selected-dot': activeIndex == 2 }"
						src="/images/carousel-dot.svg"
						:fill="carouselNavFill"
					></inline-svg>
					<inline-svg
						:class="{ 'selected-dot': activeIndex == 3 }"
						src="/images/carousel-dot.svg"
						:fill="carouselNavFill"
					></inline-svg>
					<inline-svg
						:class="{ 'selected-dot': activeIndex == 4 }"
						src="/images/carousel-dot.svg"
						:fill="carouselNavFill"
					></inline-svg>
				</div>

				<inline-svg
					class="arrow-right"
					src="/images/carousel-arrow.svg"
					fill="#ffffff"
					@click.native="next($event, true)"
				></inline-svg>
			</div>

			<div id="buttons">
				<v-button
					label="Operator"
					activeBG="#662d91"
					defaultBG="rgba(255,255,255,.5)"
					activeColor="#fff"
					defaultColor="#333"
					v-show="isDesktop || (!isDesktop && activeIndex == 1)"
					:isHovered="hoveredUser == 'operator' || activeIndex == 1"
					:isSelected="selectedUser == 'operator'"
					@mouseenter.native="onButtonEnter('operator')"
					@mouseleave.native="onButtonLeave"
					@click.native="onUserClick('operator')"
				/>
				<v-button
					label="Purchaser"
					activeBG="#ed1e79"
					defaultBG="rgba(255,255,255,.5)"
					activeColor="#fff"
					defaultColor="#333"
					v-show="isDesktop || (!isDesktop && activeIndex == 2)"
					:isHovered="hoveredUser == 'purchaser' || activeIndex == 2"
					:isSelected="selectedUser == 'purchaser'"
					@mouseenter.native="onButtonEnter('purchaser')"
					@mouseleave.native="onButtonLeave"
					@click.native="onUserClick('purchaser')"
				/>
				<v-button
					label="Transporter"
					activeBG="#f15a24"
					defaultBG="rgba(255,255,255,.5)"
					activeColor="#fff"
					defaultColor="#333"
					v-show="isDesktop || (!isDesktop && activeIndex == 3)"
					:isHovered="
						hoveredUser == 'transporter' || activeIndex == 3
					"
					:isSelected="selectedUser == 'transporter'"
					@mouseenter.native="onButtonEnter('transporter')"
					@mouseleave.native="onButtonLeave"
					@click.native="onUserClick('transporter')"
				/>
				<v-button
					label="Owner"
					activeBG="#333333"
					defaultBG="rgba(255,255,255,.5)"
					activeColor="#fff"
					defaultColor="#333"
					v-show="isDesktop || (!isDesktop && activeIndex == 4)"
					:isHovered="hoveredUser == 'owner' || activeIndex == 4"
					:isSelected="selectedUser == 'owner'"
					@mouseenter.native="onButtonEnter('owner')"
					@mouseleave.native="onButtonLeave"
					@click.native="onUserClick('owner')"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import global from "@/mixins/global.js";

	export default {
		name: "Carousel",
		mixins: [global],
		data() {
			return {
				slides: [
					{ type: "default", slideIndex: 0 },
					{ type: "operator", slideIndex: 1 },
					{ type: "purchaser", slideIndex: 2 },
					{ type: "transporter", slideIndex: 3 },
					{ type: "owner", slideIndex: 4 },
				],
				slideIndex: 0,
				interval: 6000,
				time: 6000,
				clock: null,
				paused: false,
				hoveredUser: "",
				selectedUser: "",
			};
		},
		computed: {
			activeIndex() {
				if (this.hoveredUser == "" && this.selectedUser == "") {
					return this.slides.findIndex((slide) => {
						return slide.slideIndex == this.slideIndex;
					});
				} else {
					var newIndex = this.slides.findIndex((slide) => {
						if (this.hoveredUser != "") {
							return slide.type == this.hoveredUser;
						} else {
							return slide.type == this.selectedUser;
						}
					});
					this.slideIndex = newIndex;
					return newIndex;
				}
			},
			carouselNavFill() {
				return this.activeIndex == 0 ? "#808080" : "#ffffff";
			},
		},
		methods: {
			prev(e, isNavButton = false) {
				this.slideIndex -= 1;
				if (this.slideIndex == -1) {
					this.slideIndex = this.slides.length - 1;
				}
				if (isNavButton) {
					this.time = 15000;
				} else {
					this.time = this.interval;
				}

				if (e) {
					var svg = e.target.closest("svg");
					svg.blur();
					svg.style.opacity = "1";
					setTimeout(() => {
						svg.style.opacity = "0.45";
					}, 500);
				}
			},
			next(e, isNavButton = false) {
				this.slideIndex += 1;
				if (this.slideIndex == this.slides.length) {
					this.slideIndex = 0;
				}
				if (isNavButton) {
					this.time = 15000;
				} else {
					this.time = this.interval;
				}

				if (e) {
					var svg = e.target.closest("svg");
					svg.blur();
					svg.style.opacity = "1";
					setTimeout(() => {
						svg.style.opacity = "0.45";
					}, 500);
				}
			},
			startInterval() {
				const precision = 100;
				this.clock = setInterval(() => {
					if (!this.paused) this.time -= precision;
					if (this.time <= 0) this.next();
				}, precision);
			},
			onButtonEnter(user) {
				this.hoveredUser = user;
				this.paused = true;
			},
			onButtonLeave(e) {
				this.hoveredUser = "";
			},
			onUserClick(user) {
				this.selectedUser = user;
				this.paused = true;
				if (!this.isDesktop) window.location.href = "/register.aspx";
			},
		},
		created() {
			this.startInterval();
		},
		unmounted() {
			clearInterval(this.clock);
		},
	};
</script>

<style scoped lang="scss">
	#carousel {
		width: 100%;
		flex-direction: column;
		align-items: center;
		#slides {
			width: 100%;
			position: relative;
			overflow: hidden;
			min-height: 440px;
			align-items: flex-end;
			justify-content: center;
			.slide {
				width: 100%;
				min-height: 440px;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				padding-top: 0px;
				padding-bottom: 0px;
				position: absolute;
				.slide-background {
					width: 100%;
					height: 100%;
					position: absolute;
					max-width: none;
					background-size: cover;
					animation-timing-function: linear;
					will-change: transform;
					box-shadow: 0px 2px 5px rgb(0 0 0 / 2%) inset;
				}
				.content {
					flex-direction: column;
					align-items: center;
					justify-content: center;
					color: white;
					position: absolute;
					h1 {
						font-size: 67px;
						font-weight: 600;
						text-align: center;
						line-height: 1.1em;
						margin-top: 66px;
						margin-bottom: 12px;
						width: 650px;
						color: white;
						span {
							font-weight: 600;
							line-height: 1.1em;
						}
					}
					p {
						font-size: 13px;
						text-align: left;
						line-height: 1.7em;
						display: inline;
						font-weight: 600;
						max-width: 720px;
					}
				}
				.login-button {
					position: relative;
					cursor: pointer;
					color: white;
					.login-label {
						position: absolute;
						bottom: 16px;
						right: 22px;
					}
					a {
						color: white;
					}
				}
				.join-text {
					display: inline;
					font-size: 15px;
					letter-spacing: 0px;
					margin-top: 33px;
					cursor: pointer;
					color: white;
					font-weight: 800;
					margin-left: 5px;
				}
			}

			.slide.first {
				justify-content: center;
				.content {
					width: 1292px;
					max-width: 100%;
					align-items: flex-end;

					h1 {
						width: 540px;
						text-align: right;
						color: #333;
						align-items: flex-end;
						margin: 0px;
					}
				}
			}

			#operator-slide {
				h1 {
					span:first-child {
						display: block;
					}
				}
			}

			#purchaser-slide {
				h1 {
					span:first-child {
						display: block;
					}
				}
			}

			#transporter-slide {
				h1 {
					span:first-child {
						display: block;
					}
				}
			}
		}

		#mobile-join-text {
			display: none;
		}

		#buttons {
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			position: absolute;
			padding: 11px;
			.button {
				width: calc(25% - 22px);
			}
		}
	}

	@media (max-width: 1079px) {
		#carousel {
			#slides {
				min-height: 640px;
				.slide {
					min-height: 640px;
					.content {
						height: 100%;
						padding-bottom: 165px;
						h1,
						h1 span {
							margin-top: 0px;
							font-size: 46px;
						}
						p {
							line-height: 1.6em;
							a {
								display: none;
							}
						}
					}
					.join-text {
						display: block;
						margin-top: 20px;
						text-align: center;
						font-size: 14px;
					}
				}

				.slide.first {
					.content {
						h1 {
							font-size: 58px;
						}
					}
				}
			}
			#mobile-join-text {
				display: flex;
				font-size: 14px;
				font-weight: 800;
				bottom: 147px;
				z-index: 1;
				position: absolute;
			}
			#mobile-carousel-nav {
				bottom: 95px;
				width: 340px;
				padding-left: 25px;
				padding-right: 24px;
				justify-content: space-between;
				position: absolute;
				svg.arrow-left,
				svg.arrow-right {
					height: 47px;
					width: 21.21px;
					flex: 0 auto;
					opacity: 0.45;
					transition: opacity 0.5s;
					&:hover {
						opacity: 1;
						cursor: pointer;
					}
				}
				#dots {
					width: 142px;
					align-items: center;
					justify-content: space-between;
					svg {
						width: 11px;
						height: 11px;
						opacity: 0.45;
						&.selected-dot {
							opacity: 1;
						}
					}
				}
				.arrow-left {
					justify-content: flex-start;
				}
				.arrow-right {
					transform: rotateY(180deg);
				}
			}

			#buttons {
				width: 340px;
				padding: 4px 24px 24px 24px;
				.button {
					width: 290px;
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}

	@media (max-width: 540px) {
		.slide {
			h1 {
				span {
					display: flex !important;
					align-items: center;
					justify-content: center;
					white-space: nowrap;
				}
			}
		}
	}
</style>
