<template>
	<div id="resources" class="page">
		<section id="title" :style="{
				'background-image':
					'url(' + getImage('/images/bg/bg-resources.jpg') + ')',
			}">
			<div class="text">
				<h1>Resources</h1>
				<div>
					What you need, when you need it. Find answers to frequently
					asked questions, downloadable forms, contacts and more.
				</div>
			</div>
		</section>
		<section id="faq">
			<toggle>
				<template v-slot:heading>What is the current mailing address and fax
					number?</template>
				<template v-slot:body>4888 Loop Central Drive, Ste 505<br />Houston, TX 77081<br />
					<br v-if="isMobile" />
					Phone:
					<strong><a href="tel:7136294490">713-629-4490</a></strong><br />
					<br v-if="isMobile" />
					Fax:
					<strong><a href="tel:7136296643">713-629-6643</a></strong></template>
			</toggle>
			<toggle>
				<template v-slot:heading>How do I reach the Division Order/Revenue
					department?</template>
				<template v-slot:body>
					<div>
						Royalty Relations Hotline:
						<strong>
							<a href="tel:7136294490">713-629-4490</a>
						</strong>
						(Option 4) <br /><br v-if="isMobile" />
						Royalty Relations Email:
						<strong>
							<a href="mailto:royaltyrelations@iberiamansys.com">royaltyrelations@iberiamansys.com</a>
						</strong>
						<br /><br v-if="isMobile" />
						Please include the following information in all written
						correspondence:
					</div>
					<ul>
						<li>Name</li>
						<li>Owner Number</li>
						<li>Owner SSN/TIN</li>
						<li>Company issuing payment</li>
						<li>Your telephone number</li>
					</ul>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>How do I change my address? + Download Forms</template>
				<template v-slot:body>
					<div>
						For security reasons, all address changes must be made in writing. Please download and complete
						an Owner Update form provided below OR send a written and signed letter to us with your name,
						owner number and the last 4 of your SSN/TIN for account verification. For your protection, we do
						not accept address changes by telephone.
					</div>
					<div>
						If the form for the company making your payments isn’t listed on this page, please call
						<strong>
							<a href="tel:7136294490">713-629-4490</a>
						</strong> or email <strong>
							<a href="mailto:royaltyrelations@iberiamansys.com">royaltyrelations@iberiamansys.com</a>
						</strong> to receive your Owner Update form.
					</div>
					<strong>
						<h4>FORMS:</h4>
					</strong>
					<ul class="arrow">
						<li>
							<a href="/assets/docs/OwnerUpdate_MVPUR.pdf" target="_blank">MV Purchasing Owner Update</a>
						</li>
					</ul>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>Can I set up Direct Deposit? + Download Forms</template>
				<template v-slot:body>
					<div>
						To setup a new direct deposit, or update existing banking information, please download and
						complete an EFT/Direct Deposit Authorization form provided below. Selecting EFT will prevent
						lost, stolen or mail-delayed checks. It is safe and secure.
					</div>
					<div>
						If the form for the company making your payments isn’t listed on this page, please call
						<strong>
							<a href="tel:7136294490">713-629-4490</a>
						</strong> or email <strong>
							<a href="mailto:royaltyrelations@iberiamansys.com">royaltyrelations@iberiamansys.com</a>
						</strong> to receive your Direct Deposit form.
					</div>
					<h4>FORMS:</h4>
					<ul class="arrow">
						<li>
							<a href="/assets/docs/EFT_AuthAgreement_MVPUR.pdf" target="_blank">MV Purchasing EFT/Direct Deposit Authorization</a>
						</li>
					</ul>
					<div class="small-text">
						<strong>*Please note</strong> - VOC Brazos does not
						offer Direct Deposit at this time. When it’s available,
						we will notify the owners.
					</div>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>Where is my 1099?</template>
				<template v-slot:body>
					<div>
						1099 Forms are mailed via United States Postal Service
						at the end of January. To receive a replacement or PDF
						copy, you may create an account by clicking "Login" on
						our homepage to print a PDF copy, or you may email
						<a href="mailto:royaltyrelations@iberiamansys.com">royaltyrelations@iberiamansys.com</a>
						with the following information:
					</div>
					<ul>
						<li>Owner Name</li>
						<li>Owner Number</li>
						<li>Last 4 digits of SSN or TIN</li>
					</ul>
					<div class="small-text">
						<strong>*Please note</strong> - A 1099 Form will NOT be
						issued if the following has occurred:
					</div>
					<div class="indented small-text">
						*You are a corporation (we are not required to issue a
						1099 Form to Corporations per the IRS).<br /><br v-if="isMobile" />
						*Royalty Owner with revenue less than $10 annually.<br /><br v-if="isMobile" />
						*Working Interest Owner with revenue less than $600
						annually.
					</div>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>How do I complete a Division Order I received in the mail?
				</template>
				<template v-slot:body>
					<div>
						A Division Order is a non-binding directive signed by
						the Interest Owner verifying to the Purchaser or
						Operator of a well the decimal interest of production
						owned by the owner. It also provides the owner’s
						remittance address and taxpayer identification. The
						Interest Owner should sign the Division Order and have a
						Witness sign as well.
					</div>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>Who can sign as a Witness on a Division Order?
				</template>
				<template v-slot:body>
					<div>
						A family member, friend or neighbor. It does not need to
						be notarized.
					</div>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>What do I do if an interest owner is deceased?
				</template>
				<template v-slot:body>
					<div>
						Title to real property owned by the deceased party
						passes one of two ways - either by testate succession or
						intestate succession. Testate is with a Last Will &
						Testament that has been/will be Probated. Intestate is
						without a Will, or the Will is not being Probated. In
						the absence of a Will or if the Will has not been
						Probated, title to real property passes according to the
						Laws of Descent and Distribution for the State in which
						the well(s) are located, and not the state of residence.
						Depending on the circumstances, the documentation we
						must be provided is as follows:
					</div>
					<h3>Testate Succession (With a Probated Will)</h3>
					<ul class="small-text">
						<li>Copy of Death Certificate</li>
						<li>
							Probated Last Will and Testament, and Certificate of
							filing containing Probate Case No. from Probate
							Court
						</li>
						<li>
							Order Admitting Will to Probate and Issuance of
							Letters Testamentary naming Executor of the Estate
						</li>
						<li>
							Final Decree of Distribution/Final Judgment and/or
							Determination of Heirship issued by the court (if
							applicable).
						</li>
						<li>
							Furnish Address and SSN/TIN for each party
							inheriting
						</li>
					</ul>
					<h3>Intestate Succession (Without a Probated Will)</h3>
					<ul class="small-text">
						<li>Copy of Death Certificate</li>
						<li>
							<strong>Affidavit of Heirship</strong> (Must be
							recorded in County/Parish where property/well is
							located)
						</li>
						<li>*Signature of Affiants must be notarized</li>
						<li>
							Copy of Unprobated Last Will & Testament (if
							applicable)
						</li>
						<li>
							Furnish Address and SSN/TIN for each surviving heir
							listed on the form
						</li>
					</ul>
					<h4 class="small-text">*PLEASE NOTE:</h4>
					<ol class="small-text">
						<li>
							<strong>1)&nbsp;</strong> All Testate and Intestate
							documents provided are subject to review. Additional
							information may be required after our initial
							review.
						</li>
						<li>
							<strong>2)&nbsp;</strong> All heirship and
							conveyance documents must be filed of record in the
							County/Parish where the property is located to
							maintain record title.
						</li>
						<li>
							<strong>3)&nbsp;</strong> We CANNOT accept Affidavit
							of Heirship forms completed by direct family members
							to the decedent. The form must be completed by a
							friend, acquaintance, neighbor, minister, etc., who
							knew the decedent well and is acquainted with the
							facts of the family history.
						</li>
					</ol>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>How do I transfer my interest to another party?
				</template>
				<template v-slot:body>
					<div>
						We issue revenue in accordance with record title. If you
						wish to transfer your interest to another party, please
						provide a recorded conveyance instrument (i.e., Quit
						Claim Deed, Mineral Deed, Warranty Deed, etc.)
						evidencing the transfer of interest. The conveyance
						instrument must be filed of record in the county where
						the property is located. If you have any questions
						concerning a transfer of interest, we advise that you
						consult with an oil & gas attorney.
					</div>
					<h4>Trusts:</h4>
					<ul class="small-text">
						<li>
							When a Trust is created, we require copies of the
							Trust Agreement OR Memorandum of Trust, and a
							recorded conveyance into the Trust.
						</li>
						<li>
							Probated Last Will and Testament, and Certificate of
							filing containing Probate Case No. from Probate
							Court
						</li>
						<li>
							When a Trust is dissolved, we will need a copy of
							the Dissolution of Trust or other appropriate
							recorded conveyance.
						</li>
						<li>
							Changes in Trustee: Instrument whereby the successor
							trustee was appointed, and the duties allowed.
						</li>
					</ul>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>How do I change my name on my account?
				</template>
				<template v-slot:body>
					<div>
						We require written documentation to change the name
						associated with your account. The following
						documentation is required, depending on the
						circumstances surrounding the name change:
					</div>
					<ul class="small-text">
						<li>
							<strong>Marriage or divorce:</strong> Submit a copy
							of a marriage certificate, divorce decree including
							Settlement Agreement, or other pertinent document
							evidencing the name change.
						</li>
						<li>
							<strong>Company or corporation changes name or
								merges:</strong>
							Submit a copy of the Certificate of Name Change or
							Certificate of Merger.
						</li>
					</ul>
					<h4>Other circumstances:</h4>
					<ul class="small-text">
						<li>
							<strong>Power of Attorney:</strong> Provide our
							office with a copy of the Power of Attorney document
							appointing Attorney-in-Fact. A Power of Attorney
							becomes null and void at the death of the principal.
						</li>
						<li>
							<strong>Guardianships:</strong> When an owner is
							declared incompetent, furnish Letters of
							Guardianship issued by the court.
						</li>
						<li>
							<strong>Minors:</strong> When a minor reached the
							legal age, please provide a copy of the minor’s
							Birth Certificate.
						</li>
						<li>
							<strong>Termination of Joint Tenancy Upon
								Death:</strong>
							Furnish a copy of the Death Certificate and the
							social security number of the new owner.
						</li>
						<li>
							<strong>Life Tenant:</strong> Also referred to as a
							“Life Estate”. When an owner of a Life Estate dies,
							we will need a copy of the Death Certificate and the
							names, addresses and social security numbers of the
							remaindermen.
						</li>
					</ul>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>When are royalty checks mailed?
				</template>
				<template v-slot:body>
					<div>
						Checks are mailed on the 20th of each month. If the 20th
						falls on a weekend or holiday, checks will be issued the
						next business day. Checks will not be issued until a
						minimum of $50.00 has accrued in your account.
					</div>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>What should I do if I did not receive my check?
				</template>
				<template v-slot:body>
					<div>
						Due to potential postal delays, a revenue check may
						arrive a few days late on occasion. If you have not
						received a check within 10 business days from the date
						of issue, please contact our office so the check can be
						voided and reissued on the next pay cycle.
					</div>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>What if my check is lost, stolen, or becomes outdated?
				</template>
				<template v-slot:body>
					<div>
						Contact Royalty Relations at
						royaltyrelations@iberiamansys.com to request payment if
						your check has been lost, stolen, or outdated. Please
						have the following information ready:
					</div>
					<ul>
						<li>Your Name and Owner Number</li>
						<li>Your SSN or TIN</li>
						<li>
							Check information, if available (date, check number
							and amount)
						</li>
					</ul>
				</template>
			</toggle>
			<toggle>
				<template v-slot:heading>Why does my payment differ from others in my family?
				</template>
				<template v-slot:body>
					<div>
						This occurs because ownership between family members is
						not equal, because one party has not provided us with a
						TIN/SSN, or because some family members may own an
						interest in other properties in addition to those
						commonly owned.
					</div>
				</template>
			</toggle>
		</section>
		<client-logos></client-logos>
	</div>
</template>

<script>
	import global from "@/mixins/global.js";

	export default {
		name: "Resources",
		mixins: [global],
		components: {},
		data() {
			return {};
		},
	};
</script>

<style scoped lang="scss">
	#title {
		align-items: center;
		background-size: cover;
		background-position: center;
		padding-top: 180px;
		padding-bottom: 127px;
		height: 440px;
		.text {
			width: 1220px;
			flex-direction: column;
			align-items: flex-start;
			div {
				width: 570px;
				line-height: 1.7em;
				font-weight: 600;
				font-size: 14px;
				padding-left: 30px;
			}
			h1 {
				width: 570px;
				font-size: 80px;
				display: inline;
				margin-bottom: 10px;
				padding-left: 25px;
			}
		}
	}

	@media (max-width: 1079px) {
		#title {
			background-position: left;
		}
	}

	@media (max-width: 540px) {
		#title {
			padding-top: 62px;
			padding-bottom: 62px;
			height: 640px;
			.text {
				width: 100%;
				div {
					width: 100%;
					padding-left: 0px;
					font-size: 14px;
					padding-left: 12px;
				}
				h1 {
					width: 100%;
					font-size: 45px;
					margin-bottom: 20px;
					padding-left: 0px;
				}
			}
		}
	}

	#faq {
		padding-top: 58px;
		padding-bottom: 160px;
		align-items: center;
	}

	@media (max-width: 540px) {
		#faq {
			padding: 0;
		}
	}
</style>