<template>
	<section id="client-logos">
		<img id="logos" src="/images/client-logos.jpg" />
	</section>
</template>

<script>
	export default {
		name: "ClientLogos",
		components: {},
	};
</script>

<style lang="scss">
	#client-logos {
		background-color: #f2f2f2;
		align-items: center;
		#logos {
			width: 1400px;
			background-image: url("/images/client-logos.jpg");
			background-position: center;
		}
	}
</style>
