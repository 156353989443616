<template>
	<div id="direct-deposit" class="page">
		<section id="title" :style="{
				'background-image':
					'url(' + getImage('/images/bg/bg-direct.jpg') + ')',
			}">
			<div class="text">
				<h1>Get Paid.<br><span>On Payday.</span></h1>
				<div>With free Direct Deposit from Iberia</div>
			</div>
		</section>

		<section id="paper-route">
			<h2>Skip the paper route.</h2>
			<p id="description"><strong>Direct Deposit</strong> is the fastest way to get paid. And we offer it free - because it’s also the safest.
				Unlike paper checks on trucks, we use industry standard encryption that keeps your private banking info
				hidden and secure, from start to finish. It doesn’t miss a beat (or a turn). And Direct Deposit comes
				standard with no postal delays, mail theft or dogs that just don’t know when to let go. Get your form
				today and start enjoying that, “never check the mail for a check again” feeling, on us.</p>
		</section>

		<section id="forms">
			<h2>Forms</h2>
			<a href="/assets/docs/EFT_AuthAgreement_MVPUR.pdf" target="_blank" id="pdf-link">
				<img src="/images/mvpurchasing.png" />
				<div id="text">
					<h3>MVPurchasing</h3>
					<p>Direct Deposit PDF</p>
				</div>
			</a>
			<p id="contact-info">
				If the form for the company making your payments isn’t listed on this page, please call <a href="tel:7136294490">713-629-4490</a> or email <a href="mailto:royaltyrelations@iberiamansys.com">royaltyrelations@iberiamansys.com</a> to receive your Direct Deposit form.
			</p>
		</section>

		<div id="flourish"><img src="/images/bg/bg-flourish.jpg" /></div>

		<client-logos></client-logos>
	</div>
</template>

<script>
	import global from "@/mixins/global.js";

	export default {
		name: "DirectDeposit",
		mixins: [global],
		components: {},
		data() {
			return {};
		},
	};
</script>

<style scoped lang="scss">
	#title {
		align-items: center;
		background-size: cover;
		background-position: center;
		padding-top: 100px;
		padding-bottom: 100px;
		height: 440px;
		.text {
			width: 1220px;
			flex-direction: column;
			align-items: center;
			h1 {
				font-size: 90px;
				display: inline;
				margin-bottom: 30px;
				text-align: center;
				span {
					font-weight: 600;
					color: white;
				}
			}
			div {
				line-height: 1.5em;
				font-weight: 600;
				font-size: 22px;
				color: white;
				text-align: center;
			}
		}
	}
	
	@media (max-width: 1079px) {
		#title {
			.text {
			
					h1 {
						font-size: 60px;
						margin-bottom: 80px;
					}
			
					div {
						font-size: 18px;
					}
				}
		}
	}

	@media (max-width: 540px) {
		#title {
			padding-top: 90px;
			padding-bottom: 90px;
			height: 640px;

			.text {
				width: 100%;
				max-width: 320px;
				h1 {
					width: 100%;
					font-size: 63px;
					margin-bottom: 150px;
					text-align: left;
				}
				
				div {
					width: 100%;
					font-size: 18px;
				}
			}
		}
	}
	
	#paper-route {
		align-items: center;
		padding-top: 80px;
		padding-bottom: 100px;
		h2 {
			font-size: 47px;
			font-weight: 400;
			margin-bottom: 30px;
		}
		#description {
			width: 700px;
			display: inline;
			text-align: justify;
			color: #333333;
			strong {
				display: inline;
			}
		}
		
	}
	
	@media (max-width: 540px) {
		#paper-route {
			padding-top: 50px;
			padding-bottom: 65px;
			border-bottom: 1px solid #E6E6E6;
			h2 {
				font-size: 38px;
				font-weight: 400;
				margin-bottom: 30px;
			}
		
			#description {
				font-size: 13.5px;
				text-align: left;
			}
		}
	}
	
	#forms {
		align-items: center;
		h2 {
			font-size: 17px;
			font-weight: 500;
			text-transform: uppercase;
			margin-bottom: 50px;
			text-align: center;
			display: none;
		}
		#pdf-link {
			margin-bottom: 80px;
			cursor: pointer;
	
			img {
				height: 60px;
				width: auto;
			}
	
			#text {
				flex-direction: column;
				align-items: center;
				margin-left: 12px;
	
				h3 {
					font-size: 35px;
					font-weight: 600;
					margin-top: 14px;
				}
	
				p {
					font-weight: 700;
					color: #999999;
					text-transform: uppercase;
					letter-spacing: 1px;
				}
			}
		}
	
		#contact-info {
			color: #808080;
			width: 400px;
			font-size: 12.5px;
			display: inline;
			text-align: center;
	
			a {
				display: inline;
				color: #808080;
				font-weight: 600;
			}
		}
	}
	
	@media (max-width: 540px) {
		#forms {
			padding-top: 35px;
			h2 {
				display: block;
			}
			
			#pdf-link {
				margin-bottom: 60px;

				img {
					height: 45px;
				}

				#text {

					h3 {
						font-size: 27px;
						margin-top: 10px;
					}

					p {
						font-size: 13px;
					}
				}
			}
			
			#contact-info {
				font-size: 12px;
				text-align: left;
			}
		}
	}
	
	#flourish {
		display: block;
		margin-top: -5vw;
		z-index: -1;
		img {
			width: 100%;
			display: block;
		}
	
	}
	
	@media (max-width: 1079px) {
		#flourish {
			margin-top: 10px;
		}
	}
</style>