<template>
	<div id="app">
		<Header />
		<router-view />
		<Footer />
	</div>
</template>

<script>
	import Header from "@/components/Header.vue";
	import Footer from "@/components/Footer.vue";

	import "vue2-animate/dist/vue2-animate.min.css";

	import AOS from "aos";
	import "aos/dist/aos.css";

	export default {
		name: "App",
		components: {
			Header,
			Footer,
		},
		mounted() {
			AOS.init();
		},
	};
</script>

<style lang="scss">
	$brand-purple: #662d91;
	$brand-pink: #ed1e79;
	$brand-orange: #f15a24;
	$brand-grey: #333333;

	.bg-purple {
		background: $brand-purple !important;
	}
	.bg-pink {
		background: $brand-pink !important;
	}
	.bg-orange {
		background: $brand-orange !important;
	}
	.bg-grey {
		background: $brand-grey !important;
	}

	.text-purple {
		color: $brand-purple !important;
	}
	.text-pink {
		color: $brand-pink !important;
	}
	.text-orange {
		color: $brand-orange !important;
	}
	.text-grey {
		color: $brand-grey !important;
	}
	.text-white {
		color: #fff !important;
	}

	.site-padding {
		padding-left: 8%;
		padding-right: 8%;
	}

	body,
	body * {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		border: 0;
		text-decoration: none;
		max-width: 100%;
		display: flex;
	}

	body {
		align-items: center;
		justify-content: center;
		font-family: termina, sans-serif;
		letter-spacing: 0.015em;
	}

	#app {
		width: 100%;
		align-items: center;
		flex-direction: column;
	}

	#app > * {
		width: 100%;
		flex-direction: column;
	}

	header,
	section,
	footer {
		padding-left: 6%;
		padding-right: 6%;
	}

	section,
	.page {
		flex-direction: column;
	}

	h1,
	h2,
	h3,
	h4 {
		line-height: 1em;
		display: inline;
		font-weight: 600;
		color: $brand-grey;
	}

	h1 span,
	h2 span {
		display: inline;
		font-weight: 400;
	}

	input,
	textarea {
		font-size: 14px;
		color: #333;
		font-family: termina, sans-serif;
		font-weight: 600;
	}

	input,
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		width: 270px;
		height: 40px;
		background: url("/images/form-input.png") !important;
		background-color: transparent !important;
		margin-bottom: 20px;
		padding: 12px 20px;
		background-position: bottom right !important;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		appearance: none !important;
		-webkit-box-shadow: none !important;
		border-radius: 0px !important;
	}

	textarea {
		width: 100%;
		height: 182px;
		border: 1px solid #cccccc !important;
		padding: 34px 20px;
		margin-bottom: 32px;
		border-radius: 0px !important;
		appearance: none !important;
	}

	::placeholder {
		color: #ccc;
		font-size: 14px;
		text-transform: uppercase;
	}

	input:focus,
	select:focus,
	textarea:focus,
	button:focus {
		outline: none;
	}

	svg,
	svg * {
		display: flex;
		flex: 1;
	}
</style>
