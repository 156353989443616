<template>
	<div class="button">
		<inline-svg src="/images/button-label.svg" :fill="fill"></inline-svg>
		<div class="label" :style="{ color: labelTextColor }">{{ label }}</div>
	</div>
</template>

<script>
	export default {
		name: "Button",
		props: [
			"label",
			"defaultBG",
			"activeBG",
			"defaultColor",
			"activeColor",
			"isHovered",
			"isSelected",
		],
		data() {
			return {};
		},
		computed: {
			fill() {
				return this.isHovered || this.isSelected
					? this.activeBG
					: this.defaultBG;
			},
			labelTextColor() {
				return this.isHovered || this.isSelected
					? this.activeColor
					: this.defaultColor;
			},
		},
	};
</script>

<style scoped lang="scss">
	.button {
		width: 312px;
		height: 44px;
		margin: 11px;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		svg {
			width: 312px;
			height: 44px;
			transition: fill 0.5s;
		}
		.label {
			position: absolute;
			font-size: 16px;
			transition: color 0.5s;
			text-transform: uppercase;
			font-weight: 600;
		}
	}
</style>
