<template>
	<section id="footer" class="site-padding">
		<div>
			©{{ new Date().getFullYear() }} Iberia Management Systems, Inc<br />All
			rights reserved
		</div>
	</section>
</template>

<script>
	export default {
		name: "Footer",
		components: {},
	};
</script>

<style lang="scss">
	#footer {
		text-align: center;
		padding-top: 22px;
		padding-bottom: 22px;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.4em;
		color: #b3b3b3;
		text-transform: uppercase;
		align-items: center;
	}

	@media (max-width: 1079px) {
		#footer {
			font-size: 10px;
		}
	}
</style>
