<template>
	<section id="header">
		<div class="desktop">
			<div id="logo-text">
				<a href="/"
					><inline-svg src="/images/logo-text.svg"></inline-svg
				></a>
			</div>
			<div id="logo-icon">
				<a href="/">
					<inline-svg src="/images/logo-icon.svg"></inline-svg>
				</a>
			</div>
			<nav>
				<transition name="fade" mode="in-out">
					<a
						@mouseenter="
							showDropdown = true;
							showLogin = false;
						"
						v-if="!showDropdown"
						>{{ currentRouteName }}</a
					>
				</transition>
				<div id="nav-divider"></div>
				<a @click="showDropdown = false" href="/login.aspx">Login</a>
				<transition name="fade" mode="in-out">
					<div
						v-show="showDropdown"
						@mouseleave="showDropdown = false"
						class="nav-dropdown"
					>
						<router-link v-show="showDropdown" to="/"
							>About</router-link
						>
						<a v-show="showDropdown" href="/register.aspx"
							>Join Now</a
						>
						<router-link v-show="showDropdown" to="/ach"
							>Direct Deposit</router-link
						>
						<router-link v-show="showDropdown" to="/resources"
							>Resources</router-link
						>
						<router-link v-show="showDropdown" to="/contact"
							>Contact</router-link
						>
					</div>
				</transition>
			</nav>
		</div>
		<div class="mobile">
			<div id="logo">
				<a href="/"><inline-svg src="/images/logo.svg"></inline-svg></a>
			</div>
			<nav>
				<inline-svg
					@click="showDropdown = true"
					src="/images/icon-mobile-nav.svg"
				></inline-svg>
				<transition name="fade" mode="in-out">
					<div
						id="dropdown-overlay"
						key="0"
						v-if="showDropdown"
						@click="showDropdown = false"
					></div>
				</transition>
				<transition name="fade" mode="in-out">
					<transition-group
						name="slideRight"
						v-show="showDropdown"
						class="nav-dropdown"
					>
						<router-link
							@click.native="showDropdown = false"
							v-if="showDropdown"
							key="1"
							style="animation-delay: 0.1s"
							to="/"
						>
							About
						</router-link>
						<a
							@click="showDropdown = false"
							v-if="showDropdown"
							key="2"
							style="animation-delay: 0.2s"
							href="/login.aspx"
							>Login</a
						>
						<a
							@click="showDropdown = false"
							v-if="showDropdown"
							key="3"
							style="animation-delay: 0.3s"
							href="/register.aspx"
							>Join Iberia</a
						>
						<router-link
							@click.native="showDropdown = false"
							v-if="showDropdown"
							key="4"
							style="animation-delay: 0.4s"
							to="/resources"
							>Resources</router-link
						>
						<router-link
							@click.native="showDropdown = false"
							v-if="showDropdown"
							key="5"
							style="animation-delay: 0.5s"
							to="/ach"
							>Direct Deposit</router-link
						>
						<router-link
							@click.native="showDropdown = false"
							v-if="showDropdown"
							key="6"
							style="animation-delay: 0.6s"
							to="/contact"
							>Contact</router-link
						>
					</transition-group>
				</transition>
			</nav>
		</div>
		<!--<transition name="fade" mode="in-out" style="animation-duration: 0.4s">
			<login v-if="showLogin" @close="showLogin = false" />
		</transition>-->
	</section>
</template>

<script>
	import global from "@/mixins/global.js";

	export default {
		name: "Header",
		mixins: [global],
		components: {},
		data() {
			return {
				showDropdown: false,
				showLogin: false,
			};
		},
		computed: {
			currentRouteName() {
				return this.$route.name;
			},
		},
	};
</script>

<style scoped lang="scss">
	#header {
		align-items: center;
		.desktop {
			width: 1220px;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
			padding-top: 32px;
			padding-bottom: 32px;
			position: relative;
			> * {
				width: 33.33%;
				align-items: center;
				justify-content: center;
			}
			#logo-text {
				justify-content: flex-start;
				svg {
					height: 44px;
				}
			}
			#logo-icon {
				justify-content: center;
				width: 60px;
				svg {
					width: 60px;
				}
			}
			nav {
				justify-content: flex-end;
				> a {
					font-size: 16px;
					font-weight: 600;
					color: #333333;
					text-transform: uppercase;
					padding: 10px 20px;
					cursor: pointer;
					&:last-of-type {
						padding-right: 0px;
					}
				}
				#nav-divider {
					width: 3px;
					height: 20px;
					background-color: #e6e6e6;
					margin: 11px 0px;
				}
			}
		}

		.nav-dropdown {
			width: 312px;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			position: absolute;
			right: 85px;
			top: 44px;
			z-index: 10;
			will-change: transform;
			a {
				width: 312px;
				height: 44px;
				margin-bottom: 2px;
				background: rgba(#808080, 80%);
				align-items: center;
				justify-content: flex-end;
				padding: 0px 24px;
				color: white;
				text-transform: uppercase;
				font-weight: 600;
				font-size: 16px;
				&.router-link-exact-active,
				&:hover {
					background: #333;
				}
				&:first-child {
					background-color: transparent;
					background-image: url("/images/dropdown-label-def.svg");
					background-size: cover;
					background-position: top left;
					&.router-link-exact-active,
					&:hover {
						background-color: transparent;
						background-image: url("/images/dropdown-label-sel.svg");
					}
				}
			}
		}

		.mobile {
			display: none;
		}

		@media (max-width: 1079px) {
			.desktop {
				display: none;
			}

			.mobile {
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: space-between;
				flex-direction: row;
				padding-top: 20px;
				padding-bottom: 20px;
				> * {
					width: 50%;
					align-items: center;
					justify-content: center;
				}
				#logo {
					justify-content: flex-start;
					max-height: 40px;
					max-width: 139px;
				}
				nav {
					justify-content: flex-end;
					cursor: pointer;
					svg {
						height: 25px;
						max-width: 25px;
					}
				}
			}

			.nav-dropdown {
				width: 250px;
				right: 6%;
				top: 27px;
				transform: translatez(0);
				will-change: transform;
			}

			#dropdown-overlay {
				display: flex;
				position: fixed;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				z-index: 2;
				background: rgba(#333, 87%);
			}
		}
	}
</style>
