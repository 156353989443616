<template>
	<div id="home" class="page">
		<Carousel />

		<section
			id="bet-on-iberia"
			:style="{
				'background-image':
					'url(' + getImage('/images/bg/bg-icosahedron.jpg') + ')',
			}"
		>
			<h2><span>Bet on </span><strong>IBERIA.</strong></h2>
			<div>
				<inline-svg
					id="black-arrow"
					src="/images/black-arrow.svg"
					:fill="'#333'"
				></inline-svg>
				Our clients have for 30 years. It's easy. We continue to guide
				revenue out of the labyrinth and into pocket faster and more
				accurately than anyone in the game. From regional trading outﬁts
				to billion dollar multinational corporations, it's in our
				wheelhouse. And we believe relationships are the real
				cornerstone of business done the right way. That's why our
				client's best interests are always right at home with us. And
				right in hand.
			</div>
		</section>

		<section
			id="our-work"
			:style="{
				'background-image':
					'url(' + getImage('/images/bg/bg-dark-platform.jpg') + ')',
			}"
		>
			<div id="shapes">
				<inline-svg
					class="shape diamond diamond-1"
					src="/images/diamond-1.svg"
					:fill="'#ED1E79'"
					:style="'transform: rotateY(' + offset + 'deg)'"
				></inline-svg>

				<inline-svg
					class="shape arrow arrow-1"
					src="/images/arrow-1.svg"
					:fill="'#662D91'"
					:style="'transform: rotateY(' + offset + 'deg)'"
				></inline-svg>

				<inline-svg
					class="shape diamond diamond-2"
					src="/images/diamond-2.svg"
					:fill="'#F15A24'"
					:style="'transform: rotateY(' + offset + 'deg)'"
				></inline-svg>

				<inline-svg
					class="shape arrow arrow-2"
					src="/images/arrow-2.svg"
					:fill="'#ED1E79'"
					:style="'transform: rotateY(' + offset + 'deg)'"
				></inline-svg>

				<inline-svg
					class="shape diamond diamond-3"
					src="/images/diamond-3.svg"
					:fill="'#662D91'"
					:style="'transform: rotateY(' + offset + 'deg)'"
				></inline-svg>

				<inline-svg
					class="shape arrow arrow-3"
					src="/images/arrow-3.svg"
					:fill="'#F15A24'"
					:style="'transform: rotateY(' + offset + 'deg)'"
				></inline-svg>
			</div>
			<h2
				data-aos="fade"
				data-aos-duration="3000"
				data-aos-easing="ease-in-out"
				data-aos-once="true"
			>
				Our work.
			</h2>
		</section>
		<section id="services">
			<img
				:src="getImage('/images/bg/bg-oil-droplets.jpg')"
				v-parallax="{ direction: 'up', start: 0.2, end: 0.2 }"
			/>
			<div class="inner-container">
				<div class="section">
					<h3>Revenue Management</h3>

					<div>Crude Oil Transaction History</div>
					<div>Ticket Data</div>
					<div>Ticket Pricing</div>
					<div>Run Statements</div>
					<div>Division Orders</div>
					<div>Escheating</div>
					<div>Check or ACH Payment Processing</div>
					<div>Positive Pay and Cleared Check Reconciliation</div>
					<div>Voided Checks</div>
				</div>

				<div class="section">
					<h3>Severance Tax</h3>

					<div>
						Filing and paying of severance and all
						production-related taxes
					</div>
					<div>US Federal and all US States</div>
					<div>Amended Returns and Refunds</div>
				</div>
				<div class="section no-bullets">
					<h3>Comprehensive Reporting</h3>
				</div>
				<div class="section no-bullets">
					<h3>Unclaimed Property</h3>
				</div>
				<div class="section">
					<h3>Working & Royalty Owner Relations</h3>

					<div>Contact direct line</div>
					<div>Mailing address</div>
					<div>Key instructions for online registration</div>
					<div>Key instructions for ACH and links</div>
				</div>
			</div>
		</section>

		<section
			id="way-downstream"
			:style="{
				'background-image':
					'url(' +
					getImage('/images/bg/bg-string-of-light.jpg') +
					')',
			}"
		>
			<h2>
				<span
					data-aos="fade-right"
					data-aos-duration="500"
					data-aos-offset="200"
					data-aos-easing="ease-in-out"
					data-aos-once="true"
					>Way downstream</span
				>
				<span
					data-aos="fade-right"
					data-aos-duration="500"
					data-aos-offset="200"
					data-aos-delay="500"
					data-aos-easing="ease-in-out"
					data-aos-once="true"
					>of difficult.</span
				>
			</h2>
		</section>

		<section
			id="say-hello"
			:style="{
				'background-image':
					'url(' +
					getImage('/images/bg/bg-abstract-triangles.jpg') +
					')',
			}"
		>
			<h2>
				<span>Say hello,</span><br /><strong
					data-aos="fade"
					data-aos-duration="500"
					data-aos-offset="200"
					data-aos-easing="ease-in-out"
					data-aos-once="true"
					>to goodbye.</strong
				>
			</h2>
			<p>
				Iberia transforms back office tedium into opportunities our
				clients can depend on. Systemic inefficiencies, skyrocketing
				personnel costs, tax penalties, tech antiquation, an unending
				parade of interest inquiries - it's really what our clients lose
				that sets us, and them apart.
			</p>
			<div
				class="inner-container"
				data-aos="fade"
				data-aos-duration="1000"
				data-aos-offset="0"
				data-aos-easing="ease-in-out"
				data-aos-once="true"
			>
				<div class="section">
					<h3>MUNDANE AND COMPLEX TASKS</h3>

					<div>
						Managing revenue distributions is a royal time-suck. And
						time is money. Take back both with actionable relief
						from division orders, payment processing, interest owner
						inquiries and more. Let Iberia wrap up the details so
						you can tie up more of the big picture.
					</div>
				</div>

				<div class="section">
					<h3>INCREASING COSTS</h3>

					<div>
						Good people are hard to come by. And harder to pay. Cut
						costs and reap rewards with Iberia's back office in your
						back pocket.
					</div>
				</div>
				<div class="section">
					<h3>OUTDATED TECHNOLOGY</h3>

					<div>
						Oil and gas data processing is running on fumes. Don't
						upgrade to tomorrow's status quo. Outsource to state of
						the art with Iberia's perpetual investment in automatic
						reconciliation tech and save a tidy fortune on
						implementation, software licenses, migration of legacy
						data and a learning curve the size of Texas.
					</div>
				</div>
				<div class="section">
					<h3>TAXES</h3>

					<div>
						Never miss a deadline with Iberia. We file, pay,
						correct, amend and get refunds like clockwork. Month
						after immaculate month. We can even help get past
						penalties waved.
					</div>
				</div>
				<div class="section">
					<h3>OWNER INQUIRIES</h3>

					<div>
						Stakeholders rejoice. You now have 24/7 access to
						payments, properties, tax forms, bank updates, ACH
						requests and customer service that gives a damn. When
						you need answers now, call us.
					</div>
				</div>
			</div>
		</section>

		<section
			id="experience"
			:style="{
				'background-image':
					'url(' + getImage('/images/bg/bg-hexagons.jpg') + ')',
			}"
		>
			<h2><span>Decades </span><span>of experience.</span></h2>
		</section>

		<section id="howdy">
			<img
				:src="getImage('/images/bg/bg-bubbles.jpg')"
				v-parallax="{ direction: 'up', start: 0.25, end: 0.25 }"
			/>
			<h2>Howdy.</h2>
			<p>
				Our leadership helped write the book on process reconciliation
				in the 90s and they've been balancing best sellers from Houston
				ever since. Our staff's incredible 15 year average tenure is a
				testament to our work environment and the innovative practices
				we craft to tackle our client's evolving challenges head on. In
				30 years, there isn't anything we haven't seen, or fixed. Just
				ask. We'd love to tell you in person.
			</p>
			<div class="inner-container">
				<div class="section">
					<h3>Erik Hollingshead</h3>
					<h4>OWNER, CEO</h4>
					<div>
						Erik brings a unique wealth of diversified operational
						expertise to the business of systems management. Texas
						native and proud NSW veteran from Coronado Island,
						California, you could say he’s familiar with a tough job
						done right. From partner to chief executive, his
						dedication to Iberia and its clients is steadfast. And
						with generational investments in its people, technology
						and principles, Erik has positioned Iberia to lead the
						oil and gas reconciliation sector into the next decade,
						and beyond. He currently resides in Sugar Land, Texas
						with his wife and children.
					</div>
				</div>

				<div class="section">
					<h3>Chris Dulin</h3>
					<h4>CHIEF FINANCIAL OFFICER</h4>
					<div>
						Chris began his career in accounting with Deloitte in
						2005. In 2013 he took a giant leap and launched his own
						firm, Dulin Hassett PC, in partnership with a colleague.
						His firm served small and medium sized businesses and
						their owners as an outsourced team of CFOs. Chris’s
						clients thrived - combined, they grew by hundreds of
						millions in revenues and hundreds in employee headcount.
						In 2021, he sold his successful firm to work for his
						favorite client full time. He lives in Boerne, TX with
						his wife and three children.
					</div>
				</div>
				<div class="section">
					<h3>Eric Hintz</h3>
					<h4>CHIEF INFORMATION OFFICER</h4>

					<div>Bio coming soon.</div>
				</div>
				<div class="section">
					<h3>Melissa Fontana</h3>
					<h4>CHIEF OF DIVISION ORDERS</h4>

					<div>Bio coming soon.</div>
				</div>
				<div class="section">
					<h3>LaTonya Richmond</h3>
					<h4>CHIEF OF REVENUE DISTRIBUTION</h4>

					<div>Bio coming soon.</div>
				</div>
			</div>
		</section>

		<client-logos></client-logos>
	</div>
</template>

<script>
	import Carousel from "@/components/Carousel.vue";
	import global from "@/mixins/global.js";

	export default {
		name: "Home",
		mixins: [global],
		components: {
			Carousel,
		},
		data() {
			return {
				shapes: [],
			};
		},
	};
</script>

<style scoped lang="scss">
	#bet-on-iberia {
		align-items: center;
		background-size: cover;
		background-position: center;
		padding-top: 142px;
		padding-bottom: 142px;
		height: 572px;
		> div {
			max-width: 720px;
			position: relative;
			line-height: 1.5em;
			font-weight: 500;
			#black-arrow {
				width: 84px;
				position: absolute;
				left: -108px;
				top: calc(50% - 93px);
			}
		}
		h2 {
			width: 720px;
			font-size: 51px;
			display: inline;
			margin-bottom: 30px;
			font-weight: normal;
			text-align: center;
			strong {
				font-weight: 600;
				display: inline;
			}
		}
	}

	@media (max-width: 1079px) {
		#bet-on-iberia {
			height: 640px;
			padding-top: 67px;
			justify-content: center;
			> div {
				font-size: 13px;
				line-height: 1.6em;
				#black-arrow {
					display: none;
				}
			}
			h2 {
				text-align: left;
				font-size: 48px;
				margin-bottom: 52px;
			}
		}
	}

	@media (max-width: 540px) {
		#bet-on-iberia {
			justify-content: flex-start;
		}
	}

	#our-work {
		height: 440px;
		background-image: url("/images/bg-dark-platform.jpg");
		background-size: cover;
		background-position: center;
		justify-content: flex-end;
		align-items: center;
		#shapes {
			width: 600px;
			height: 122px;
			justify-content: space-between;
			align-items: center;
			will-change: transform;
			.shape {
				transform-origin: center;
				will-change: transform;

				will-change: transform;
			}
			.diamond {
				width: 22px;
				height: 22px;
			}
			.arrow {
				width: 122px;
				height: 122px;
			}
		}
		h2 {
			color: white;
			font-size: 69px;
			margin-top: 100px;
			margin-bottom: 50px;
		}
	}

	@media (max-width: 1600px) {
		#our-work {
			h2 {
				margin-top: 75px;
				margin-bottom: 75px;
			}
		}
	}

	@media (max-width: 1079px) {
		#our-work {
			height: 360px;
			#shapes {
				height: 60px;
				.diamond {
					width: 11px;
					height: 11px;
				}
				.arrow {
					width: 60px;
					height: 60px;
				}
			}
			h2 {
				font-size: 47px;
				margin-bottom: 70px;
			}
		}
	}

	@media (max-width: 480px) {
		#our-work {
			h2 {
				margin-top: 75px;
				margin-bottom: 95px;
			}
		}
	}

	#services {
		padding-top: 72px;
		padding-bottom: 72px;
		align-items: center;
		position: relative;
		overflow: hidden;
		justify-content: center;
		.inner-container {
			width: 750px;
			flex-direction: column;
			position: relative;
			overflow: hidden;
			padding: 112px 66px;
			background-image: url("/images/text-overlay-1.png");
			background-size: cover;
			background-position: bottom right;
			.section {
				flex-direction: column;
				align-items: center;
				text-align: center;
				color: white;
				z-index: 2;
				margin-bottom: 40px;
				font-weight: 500;
				font-size: 15px;
				line-height: 1.8em;
				h3 {
					font-size: 29px;
					margin-bottom: 20px;
					line-height: 1.2em;
					color: white;
				}
				&:last-child {
					margin-bottom: 0px;
				}
				&.no-bullets h3 {
					margin-bottom: 0px;
				}
			}
		}
	}

	@media (max-width: 1079px) {
		#services {
			padding-top: 66px;
			padding-bottom: 66px;
			.inner-container {
				padding: 34px 16px;
				.section {
					font-size: 12px;
					h3 {
						font-size: 22px;
					}
				}
			}
		}
	}

	#way-downstream {
		height: 440px;
		background-image: url("/images/bg-string-of-light.jpg");
		background-size: cover;
		background-position: center;
		align-items: center;
		justify-content: center;
		h2 {
			width: 850px;
			color: white;
			font-size: 78px;
			font-weight: 600;
			text-align: center;
			line-height: 1.2em;
			span {
				display: flex;
				font-weight: 600;
				align-items: center;
				justify-content: center;
			}
		}
	}

	@media (max-width: 1079px) {
		#way-downstream {
			height: 360px;
			h2 {
				font-size: 50px;
			}
		}
	}

	@media (max-width: 600px) {
		#way-downstream {
			h2 {
				font-size: 38px;
			}
		}
	}

	#say-hello {
		padding-top: 140px;
		padding-bottom: 140px;
		background-image: url("/images/bg-abstract-triangles.jpg");
		background-size: cover;
		background-position: center;
		align-items: center;
		justify-content: center;
		h2 {
			font-size: 48px;
			margin-bottom: 44px;
			text-align: center;
			strong {
				font-weight: 600;
			}
		}
		p {
			width: 700px;
			margin-bottom: 50px;
			font-weight: 500;
		}
		.inner-container {
			width: 635px;
			flex-direction: column;
			position: relative;
			overflow: hidden;
			padding: 48px 54px;
			background-image: url("/images/text-overlay-2.png");
			background-size: cover;
			background-position: bottom right;
			.section {
				flex-direction: column;
				align-items: flex-start;
				text-align: left;
				color: white;
				z-index: 2;
				margin-bottom: 44px;
				font-weight: 600;
				font-size: 15px;
				line-height: 1.8em;
				h3 {
					font-size: 18px;
					margin-bottom: 26px;
				}
				div {
					font-size: 13px;
					color: #333;
				}
				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
	}

	@media (max-width: 1079px) {
		#say-hello {
			padding-top: 65px;
			padding-bottom: 82px;
			h2 {
				width: 635px;
				font-size: 34px;
				text-align: left;
				padding-left: 18px;
			}
			p {
				width: 635px;
				font-size: 13px;
				line-height: 1.6em;
				margin-bottom: 62px;
				padding-left: 18px;
				padding-right: 20px;
			}
			.inner-container {
				padding: 24px 18px;
				.section {
					margin-bottom: 30px;
					h3 {
						font-size: 13px;
						margin-bottom: 18px;
					}
					div {
						font-size: 11px;
						line-height: 1.6em;
					}
				}
			}
		}
	}

	#experience {
		height: 440px;
		background-image: url("/images/bg-hexagons.jpg");
		background-size: cover;
		background-position: center;
		align-items: center;
		justify-content: center;
		h2 {
			font-size: 69px;
			text-align: center;
			span {
				font-weight: 600;
			}
		}
	}

	@media (max-width: 1079px) {
		#experience {
			height: 360px;
			h2 {
				font-size: 38px;
				span {
					display: flex;
					align-items: center;
					justify-content: center;
					white-space: nowrap;
				}
			}
		}
	}

	#howdy {
		padding-top: 260px;
		padding-bottom: 260px;
		align-items: center;
		position: relative;
		overflow: hidden;
		justify-content: center;
		color: white;
		h2 {
			font-size: 48px;
			margin-bottom: 36px;
			text-align: center;
			z-index: 2;
			color: white;
		}
		p {
			width: 700px;
			margin-bottom: 152px;
			line-height: 1.7em;
			font-weight: 500;
			z-index: 2;
		}
		.inner-container {
			width: 528px;
			flex-direction: column;
			position: relative;
			overflow: hidden;
			.section {
				flex-direction: column;
				align-items: flex-start;
				text-align: left;
				color: white;
				z-index: 2;
				margin-bottom: 72px;
				font-weight: 500;
				font-size: 15px;
				h3 {
					font-size: 28px;
					margin-bottom: 18px;
					color: white;
				}
				h4 {
					font-size: 18px;
					margin-bottom: 18px;
					color: white;
				}
				div {
					font-size: 13px;
					line-height: 1.7em;
				}
				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
	}

	@media (max-width: 1079px) {
		#howdy {
			padding-top: 84px;
			padding-bottom: 188px;
			h2 {
				font-size: 32px;
				margin-bottom: 62px;
			}
			p {
				font-size: 13px;
				line-height: 1.6em;
				margin-bottom: 70px;
			}
			.inner-container {
				.section {
					margin-bottom: 42px;
					h3 {
						font-size: 18px;
						margin-bottom: 12px;
					}
					h4 {
						font-size: 10px;
						margin-bottom: 16px;
					}
					div {
						font-size: 11px;
					}
				}
			}
		}
	}

	#client-logos {
		background-color: #f2f2f2;
		align-items: center;
		#logos {
			width: 1400px;
			background-image: url("/images/client-logos.jpg");
			background-position: center;
		}
	}
</style>
