<template>
	<div id="contact">
		<section
			class="form"
			:style="{
				'background-image':
					'url(' + getImage('/images/bg/bg-grey-city.jpg') + ')',
			}"
		>
			<h1 class="mobile">Contact Us</h1>
			<div class="inner-container">
				<h1 class="desktop">Contact Us</h1>
				<div class="fields">
					<input
						type="text"
						name="name"
						placeholder="Name"
						v-model="form.name"
					/>
					<input
						type="text"
						name="phone"
						placeholder="Phone"
						v-model="form.phone"
					/>
					<input
						type="text"
						name="email"
						placeholder="Email"
						v-model="form.email"
					/>
					<textarea
						name="message"
						rows="10"
						cols="30"
						placeholder="Message"
						v-model="form.message"
					></textarea>
				</div>
				<v-button
					:label="isSending ? 'Sending...' : 'Send'"
					activeBG="#333"
					defaultBG="#333"
					activeColor="#fff"
					defaultColor="#fff"
					:isHovered="submitActive"
					@mouseenter.native="submitActive = true"
					@mouseleave.native="submitActive = false"
					@click.native="submitForm()"
				/>
				<div id="message-sent" v-if="messageSent">Message sent.</div>
			</div>
		</section>
		<section id="info">
			<div>
				Iberia Management Systems<br />4888 Loop Central Dr Ste 505<br />Houston,
				TX 77081<br /><br /><a href="mailto:support@iberiamansys.com"
					>SUPPORT@IBERIAMANSYS.COM</a
				><span> | </span>
				<div class="phone">
					<a href="tel:7136294490">713-629-4490</a>
				</div>
			</div>
		</section>
		<client-logos></client-logos>
	</div>
</template>

<script>
	import global from "@/mixins/global.js";
	import axios from "axios";

	export default {
		name: "Contact",
		mixins: [global],
		data() {
			return {
				form: {
					name: "",
					phone: "",
					email: "",
					message: "",
				},
				submitActive: false,
				isSending: false,
				messageSent: false,
			};
		},
		computed: {
			formFilledOut() {
				return (
					this.form.name != "" &&
					this.form.email != "" &&
					this.form.message != ""
				);
			},
		},
		methods: {
			setCookie() {
				document.cookie = "Name=" + this.form.name;
				document.cookie = "Phone=" + this.form.phone;
				document.cookie = "Email=" + this.form.email;
				document.cookie = "Message=" + this.form.message;
				document.cookie = "SameSite=None";
				document.cookie = "Secure";
			},
			submitForm() {
				if (!this.formFilledOut || this.isSending) return;

				this.isSending = true;

				this.setCookie();

				axios({
					method: "post",
					url: "/processcontactpage.aspx",
					data: this.form,
					withCredentials: true,
				})
					.then((response) => {
						this.isSending = false;
						this.messageSent = true;
						this.clearForm();
						console.log(response);
					})
					.catch((error) => {
						this.isSending = false;
						console.log(error);
					});
			},
			clearForm() {
				this.form.name = "";
				this.form.phone = "";
				this.form.email = "";
				this.form.message = "";
			},
		},
	};
</script>

<style scoped lang="scss">
	.form {
		padding-top: 156px;
		padding-bottom: 142px;
		align-items: center;
		justify-content: center;
		background-size: cover;
		background-position: top right;
		h1.mobile {
			display: none;
		}
		.inner-container {
			width: 760px;
			flex-direction: column;
			align-items: center;
			position: relative;
			overflow: hidden;
			padding: 64px 102px;
			background-image: url("/images/text-overlay-3.png");
			background-size: cover;
			background-position: bottom right;

			h1 {
				text-transform: uppercase;
				margin-bottom: 42px;
			}
			.fields {
				justify-content: space-between;
				flex-wrap: wrap;
				input {
					border-left: solid 1px #ccc;
					&[name="name"] {
						width: 100%;
						background-image: url("/images/form-input-full.png") !important;
					}
				}
			}
			.button {
				margin: 0;
			}
		}
	}

	#info {
		padding-top: 56px;
		padding-bottom: 56px;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: #fff;
		text-transform: uppercase;
		background-color: #333;
		font-weight: 600;
		font-size: 17px;
		line-height: 1.4em;
		div {
			display: inline-block;
			span {
				display: inline;
				padding: 0px 5px;
			}
			.phone {
				display: inline;
			}
		}
		a {
			color: white;
			justify-content: center;
			display: inline;
		}
	}

	#message-sent {
		margin-top: 30px;
		position: absolute;
		bottom: 21px;
		font-size: 14px;
	}

	@media (max-width: 1079px) {
		.form {
			padding-top: 54px;
			padding-bottom: 50px;
			h1.mobile {
				display: flex;
				font-size: 29px;
				text-transform: uppercase;
				margin-bottom: 54px;
			}
			.inner-container {
				width: 325px;
				padding: 34px 16px;
				h1 {
					display: none;
				}
				.fields {
					justify-content: center;
					input {
						width: 290px;
						border-left: 0;
						&[name="name"] {
							width: 290px;
							background-image: url("/images/form-input.png") !important;
						}
					}
					textarea {
						width: 290px;
					}
				}
			}
		}

		#info {
			font-size: 13px;
			div {
				span {
					display: none;
				}
				.phone {
					margin-top: 1.4em;
					display: block;
				}
			}
		}
	}
</style>
