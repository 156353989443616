import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Button from "./components/Button.vue";
import ClientLogos from './components/ClientLogos.vue'
import Toggle from './components/Toggle.vue'
import Login from './views/Login.vue'
import { InlineSvgPlugin } from 'vue-inline-svg'
import parallax from '@allardyce/parallax'
import VShowSlide from 'v-show-slide'

Vue.config.productionTip = false

Vue.component('v-button', Button)
Vue.component('client-logos', ClientLogos)
Vue.component('toggle', Toggle)
Vue.component('login', Login)

Vue.use(InlineSvgPlugin)
Vue.use(parallax)
Vue.use(VShowSlide)

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')