<template>
	<div id="login">
		<div class="form">
			<h1>Login</h1>
			<div class="close" @click="$emit('close')">X</div>
			<div class="fields">
				<input
					type="email"
					name="email"
					placeholder="Email"
					v-model="email"
				/>
				<input
					type="password"
					name="password"
					placeholder="Password"
					v-model="password"
				/>
			</div>
			<v-button
				label="Submit"
				activeBG="#333"
				defaultBG="#333"
				activeColor="#fff"
				defaultColor="#fff"
				:isHovered="submitActive"
				@mouseenter.native="submitActive = true"
				@mouseleave.native="submitActive = false"
				@click.native="submitForm()"
			/>
			<a href="">Forgot Password ></a>
		</div>
	</div>
</template>

<script>
	import global from "@/mixins/global.js";

	export default {
		name: "Login",
		mixins: [global],
		data() {
			return {
				email: "",
				password: "",
				submitActive: false,
			};
		},
		computed: {
			formFilledOut() {
				return this.email != "" && this.password != "";
			},
		},
		methods: {
			submitForm() {},
		},
	};
</script>

<style scoped lang="scss">
	#login {
		position: absolute;
		width: 100%;
		height: 1000%;
		background-color: #f2f2f2;
		top: 131px;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 10;
		justify-content: center;
		.form {
			margin-top: 130px;
			align-items: center;
			flex-direction: column;
			position: relative;
			h1 {
				text-transform: uppercase;
				font-size: 59px;
				margin-bottom: 78px;
			}
			.fields {
				justify-content: space-between;
				flex-wrap: wrap;
				margin-bottom: 38px;
				width: 645px;
				input {
					width: 312px;
					height: 44px;
				}
			}
			.button {
				margin: 0px 0px 28px 0px;
				width: 312px;
			}
			> a {
				font-size: 13px;
				color: #333;
				text-transform: uppercase;
				font-weight: 600;
			}
			.close {
				font-size: 30px;
				font-weight: 600;
				position: absolute;
				top: 0px;
				right: 0px;
				cursor: pointer;
			}
		}
	}

	@media (max-width: 1079px) {
		#login {
			top: 0px;
			background-color: rgba(#333333, 0.81);
			padding: 0px 6%;
			.form {
				margin-top: 160px;
				padding-bottom: 50px;
				h1 {
					display: flex;
					color: white;
					margin-bottom: 60px;
					justify-content: center;
				}
				.fields {
					width: 290px;
					margin-bottom: 0px;
					justify-content: center;
					input {
						width: 290px;
						height: 40px;
						background: url("/images/form-input-login.png") !important;
						background-position: bottom right !important;
					}
				}
				.button {
					width: 290px;
				}
				> a {
					color: white;
				}
				.close {
					color: white;
					position: fixed;
					top: 17px;
					right: 6%;
				}
			}
		}
	}
</style>
